<template>
  <v-card>
    <v-card-title style="gap: 8px;" class="align-center flex-wrap">
      <v-icon>mdi-information</v-icon>
      <span class="text-uppercase text-subtitle-2 font-weight-bold">Información de solicitud</span>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div>
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Tipo de solicitud
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ nombreTipoSolicitud ?? '-' }}</p>
          </div>
          <div class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Nombre
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ nombreCompleto ?? '-' }}</p>
          </div>
          
          <div class="mt-4" v-if="idTipoSolicitud == 2">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Estado
            </h6>
            <v-chip 
              v-if="readonly"
              :color="estadoUsuario ? 'success' : 'error'" 
              class="white--text justify-center" 
              style="min-width: 115px;"
              label
            >
              {{ estadoUsuario ? 'Habilitado' : 'Deshabilitado' }}
            </v-chip>
            <v-switch
              v-else
              color="success"
              class="mt-0 d-flex align-center"
              :ripple="false"
              :readonly="$route.params.id != null"
              hide-details
              v-model="compEstadoUsuario"
            >
            <template #prepend>
              <v-chip 
                :color="estadoUsuario ? 'success' : 'error'" 
                class="white--text justify-center" 
                style="min-width: 115px;"
                label
              >
                {{ estadoUsuario ? 'Habilitado' : 'Deshabilitado' }}
              </v-chip>
            </template>
            </v-switch>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Identificación tributaria según origen
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ nombreNacionalidad ?? '-' }}</p>
          </div>
          <div class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Número de documento
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ numeroDocumento ?? '-' }}</p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>  
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    readonly: { type: Boolean, default: false }
  },
  computed: {
    ...mapState("solicitudCuenta", ["formInfoGeneral", "estadoSolicitud"]),
    ...mapState({
      idTipoSolicitud: (state) =>
        state?.solicitudCuenta?.formInfoGeneral?.tipoSolicitud?.id,
      nombreTipoSolicitud: (state) =>
        state?.solicitudCuenta?.formInfoGeneral?.tipoSolicitud?.nombre,
      nombreNacionalidad: (state) =>
        state?.solicitudCuenta?.formInfoGeneral?.nacionalidad?.nombre,
      numeroDocumento: (state) =>
        state?.solicitudCuenta?.formInfoGeneral?.numeroDocumento,
      estadoUsuario: (state) =>
        state?.solicitudCuenta?.formInfoGeneral?.estadoUsuario,
    }),
    ...mapGetters("solicitudCuenta", {
      nombreCompleto: "getFullName",
    }),
    compEstadoUsuario: {
      get() {
        return this.estadoUsuario;
      },
      set(value) {
        this.$store.commit("solicitudCuenta/updateestadoUsuario", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-input__control) {
  width: auto;
  max-width: min-content;
}
</style>