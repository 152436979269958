<template>
  <v-card>
    <v-card-title style="gap: 8px;" class="align-center flex-wrap">
      <v-icon>mdi-account-circle</v-icon>
      <span class="text-uppercase text-subtitle-2 font-weight-bold">Información de usuario</span>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <InformacionUsuarioLectura v-if="readonly" />
      <template v-else>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :clearable="!verifyRouteContainId"
              :error-messages="emailUsuarioError"
              :readonly="verifyRouteContainId"
              :value="emailUsuario"
              @blur="$touchValue('emailUsuario')"
              @input="updateEmailUsuario"
              label="Correo electrónico *"
              maxLength="70"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :clearable="!verifyRouteContainId"
              :error-messages="telefonoUsuarioError"
              :readonly="verifyRouteContainId"
              :value="telefonoUsuario"
              @blur="$touchValue('telefonoUsuario')"
              @input="updateTelefonoUsuario"
              label="Teléfono *"
              maxLength="8"
              outlined
              v-mask="'#'.repeat(8)"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              :clearable="!verifyRouteContainId"
              :error-messages="profesionUsuarioError"
              :items="ctlProfesiones"
              :readonly="verifyRouteContainId"
              :value="profesionUsuario?.id"
              @blur="$touchValue('profesionUsuario')"
              @input="updateProfesionUsuario"
              item-text="nombre"
              item-value="id"
              label="Profesión *"
              no-data-text="Sin datos"
              outlined
              return-object
            />
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <!-- TODO: agregar url de documento -->
            <a
              @click="obtainLinkDocument(urlDocumentoInstitucional)"
              v-if="urlDocumentoInstitucional && verifyRouteContainId"
              class="secondary--text"
              target="_blank"
            >
              ver archivo cargado [ descarga ]
            </a>
      
            <v-file-input
              v-else-if="!verifyRouteContainId"
              :error-messages="tituloUsuarioError"
              @blur="$touchValue('tituloUsuario')"
              @change="updateTituloUsuario"
              accept="application/pdf"
              clearable
              :label="labelFileInput"
              outlined
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              :value="tituloUsuario"
            >
              <template #append>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                      depressed
                      @click="obtainLinkDocument(urlDocumentoInstitucional)"
                      v-if="urlDocumentoInstitucional"
                      icon
                    >
                      <v-icon> mdi-download </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar documento actual</span>
                </v-tooltip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              :clearable="!verifyRouteContainId"
              :error-messages="rolPerfilUsuarioError"
              :disabled="!!perfilRolPreSeleccionado"
              :items="verifyRolPerfil"
              :readonly="verifyRolPerfilUsuario"
              :value="rolPerfilUsuario?.id"
              @blur="$touchValue('rolPerfilUsuario')"
              @input="updateRolPerfilUsuario"
              @change="verificarJefes"
              item-text="nombre"
              item-value="id"
              return-object
              label="Rol o perfil *"
              no-data-text="Sin datos"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <!-- hacer el elemento readonly -->
            <vc-date-picker
              v-if="!verifyRouteContainId"
              :min-date="minDate"
              locale="es"
              mode="date"
              :model-config="modelConfig"
              :popover="config_calendar"
              :value="fechaCaducidadUsuario"
              @input="updateFechaCaducidadUsuario"
            >
              <template v-slot="{ inputEvents }">
                <v-text-field
                  :error-messages="fechaCaducidadUsuarioError"
                  :value="fechaCaducidadUsuario"
                  @blur="$touchValue('fechaCaducidadUsuario')"
                  class="border roundend focus:outline-none focus:border-blue-300"
                  label="Fecha de caducidad *"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>
            <v-text-field
              v-else
              :value="fechaCaducidadUsuario"
              label="Fecha de caducidad *"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :clearable="!verifyRouteContainId"
              :error-messages="cargoUsuarioError"
              :readonly="verifyRouteContainId"
              :value="cargoUsuario"
              :rules="cargoRule"
              @blur="$touchValue('cargoUsuario')"
              @input="updateCargoUsuario"
              label="Cargo funcional *"
              maxLength="75"
              outlined
            />
          </v-col>
          <v-col cols="0" md="6" />
          <v-col cols="12">
            <v-textarea
              :clearable="!verifyRouteContainId"
              :error-messages="infoAdicionalUsuarioError"
              :readonly="verifyRouteContainId"
              :value="infoAdicionalUsuario"
              @blur="$touchValue('infoAdicionalUsuario')"
              @input="updateInfoAdicionalUsuario"
              auto-grow
              counter="500"
              label="Información adicional"
              maxLength="500"
              outlined
              row-height="30"
              rows="4"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              :clearable="!verifyRouteContainId"
              :error-messages="divisionUsuarioError"
              :items="verifyUnidad"
              :readonly="verifyRouteContainId"
              :disabled="verifyDUDA"
              :value="divisionUsuario?.id"
              @blur="$touchValue('divisionUsuario')"
              @input="updateDivisionUsuario"
              @change="verificarJefes"
              :item-text="item =>`${item?.nombre} - (${item?.institucion})`"
              item-value="id"
              label="División, Unidad, Departamento o Área a la que pertenece *"
              no-data-text="Sin datos"
              outlined
              return-object
            >
                <template v-slot:item="{ item }">
                    <div>
                      <span>{{ item.nombre }}</span> - <span class="primary--text font-weight-bold">{{ item.institucion }}</span>
                    </div>
                </template>
            </v-autocomplete>
      
          </v-col>
          <v-col class="d-flex align-center" cols="12" md="6">
            <v-btn
              :block="$vuetify.breakpoint.xs"
              :disabled="divisionUsuario == null"
              @click="agregarUnidadUsuario"
              color="btnMinsal"
              dense
              v-if="verifyMoreThanOneUnidad && !verifyDUDA"
            >
              <v-icon class="bgWhite--text"> mdi-plus </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="verifyMoreThanOneUnidad && !verifyDUDA">
            <v-data-table
              :headers="headers"
              :items="stckUnidades"
              class="mt-5"
              hide-default-footer
              disable-pagination
            >
              <template #[`item.actions`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="btnMinsal"
                      @click="elimminarUnidadUsuario(item.id)"
                      dark
                      :disabled="verifyRouteContainId"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" v-if="formInfoGeneral?.tipoSolicitud?.id == 2">
            <!-- mostrar solo texto enriquecido en base a verifyRouteContainId -->
            <AppRichTextComponent
              v-if="!verifyRouteContainId"
              :value="detalleModificacionUsuario"
              @input="updateDetalleModificacionUsuario"
              placeholder="Detalle de modificaciones a realizar"
            />
            <div class="mt-6" v-else>
              <span> Detalle de modificaciones a realizar</span>
              <v-divider class="pb-3 my-2" />
              <div class="text-wrap" v-html="detalleModificacionUsuario" />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import InformacionUsuarioLectura from './InformacionUsuarioLectura.vue';
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { isNil } from "lodash";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "FormMainCuentaComponent",
  components: { InformacionUsuarioLectura },
  props: {
    creador: {
      type: Number,
    },
    estadoSolicitud: {
      type: Number,
      default: 1,
    },
    perfilRolPreSeleccionado: { type: [Number, String] },
    readonly: { type: Boolean, default: false },
  },
  validations: {
    tituloUsuario: {
      fileNameValid,
      required,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  data: () => ({
    btnSolicitar: false,
    showDocument: false,
    verifyDUDA: false,
    document: null,
    ctlProfesiones: [],
    cargoRule:[(v) => (!v || /^(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1])+$/g.test(v)) || "No utilice números en este campo"],
    ctlRolesPerfiles: [],
    ctlDUDA: [],
    headers: [
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        text: "Eliminar",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false,
      },
    ],
    modelConfig: {
      type: "string",
      mask: "DD/MM/YYYY",
    },
    config_calendar: {
      visibility: "click",
    },
    counter_response: 0,
  }),
  computed: {
    ...mapState("solicitudCuenta", ["formInfoGeneral"]),
    ...mapGetters("solicitudCuenta", ["$v"]),
    ...mapState({
      idUsuarioEnLinea: (state) => state.userInfo.user.id,
      selectedUnidad: (state) => state.selectedUnidad,
      cargoUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.cargoUsuario,
      divisionUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.divisionUsuario,
      stckUnidades: (state) =>
        state.solicitudCuenta.formMainCuenta.stckUnidades,
      emailUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.emailUsuario,
      fechaCaducidadUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.fechaCaducidadUsuario,
      infoAdicionalUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.infoAdicionalUsuario,
      profesionUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.profesionUsuario,
      rolPerfilUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.rolPerfilUsuario,
      telefonoUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.telefonoUsuario,
      tituloUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.tituloUsuario,
      urlDocumentoInstitucional: (state) =>
        state.solicitudCuenta.formMainCuenta.urlDocumentoInstitucional,
      detalleModificacionUsuario: (state) =>
        state.solicitudCuenta.formMainCuenta.detalleModificacionUsuario,
    }),
    verifyRouteContainId() {
      if (this.readonly) return true;

      if (
        this.$route.params.id &&
        this.creador === this.idUsuarioEnLinea &&
        this.estadoSolicitud == 2
      ) {
        return false;
      } else if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
    verifyRolPerfilUsuario() {
      return this.verifyRouteContainId || this.rolPerfilUsuario != null;
    },
    verifyMoreThanOneUnidad() {
      // return [1, 7].includes(this.rolPerfilUsuario?.id);
      return false;
    },
    verifyUnidad() {
      if (
        this.divisionUsuario == null ||
        this.haveRole("ROLE_SOLICITUD_USUARIO_APROBADO_TITULAR")
      ) {
        if (this.ctlDUDA.length == 0) {
          this.fetchDUDA();
          return this.ctlDUDA;
        } else {
          return this.ctlDUDA;
        }
      } else {
        // this.verifyRouteContainId ? (this.verifyDUDA = false) : null;
        // this.ctlDUDA = [];
        // this.ctlDUDA.push(this.divisionUsuario);
        // console.log('this.ctlDUDA', this.ctlDUDA)
        return this.ctlDUDA;
      }
    },
    verifyRolPerfil() {
      if (this.rolPerfilUsuario == null) {
        if (this.ctlRolesPerfiles.length == 0) {
          this.fetchRolesPerfiles();
          return this.ctlRolesPerfiles;
        } else {
          return this.ctlRolesPerfiles;
        }
      } else {
        this.ctlRolesPerfiles = [];
        this.ctlRolesPerfiles.push(this.rolPerfilUsuario);
        return this.ctlRolesPerfiles;
      }
    },
    minDate() {
      return moment().add(2, "days").format("YYYY-MM-DD");
    },
    labelFileInput() {
      return "Comprobante de nombramiento *";
    },
    emailUsuarioError() {
      const errors = [];
      if (!this.$v.emailUsuario.$dirty) return errors;
      !this.$v.emailUsuario.required &&
        errors.push("Correo electrónico es requerido.");
      !this.$v.emailUsuario.email &&
        errors.push("Correo electrónico no es válido.");
      return errors;
    },
    telefonoUsuarioError() {
      const errors = [];
      if (!this.$v.telefonoUsuario.$dirty) return errors;
      !this.$v.telefonoUsuario.required &&
        errors.push("Teléfono es requerido.");
      !this.$v.telefonoUsuario.numeric && errors.push("Teléfono no es válido.");
      !this.$v.telefonoUsuario.containNumber &&
        errors.push("Teléfono debe comenzar con 2, 6 o 7.");
      !this.$v.telefonoUsuario.minLength &&
        errors.push("Teléfono debe tener al menos 8 dígitos.");
      return errors;
    },
    profesionUsuarioError() {
      const errors = [];
      if (!this.$v.profesionUsuario.$dirty) return errors;
      !this.$v.profesionUsuario.required &&
        errors.push("Profesión es requerido.");
      return errors;
    },
    tituloUsuarioError() {
      const errors = [];
      if (!this.$v.tituloUsuario.$dirty) return errors;
      !this.$v.tituloUsuario.required &&
        errors.push("Comprobante de nombramiento es requerido");
      !this.$v.tituloUsuario.fileNameValid &&
        errors.push("El archivo debe ser de tipo PDF.");
      !this.$v.tituloUsuario.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    rolPerfilUsuarioError() {
      const errors = [];
      if (!this.$v.rolPerfilUsuario.$dirty) return errors;
      !this.$v.rolPerfilUsuario.required &&
        errors.push("Rol/Perfil es requerido.");
      return errors;
    },
    fechaCaducidadUsuarioError() {
      const errors = [];
      if (!this.$v.fechaCaducidadUsuario.$dirty) return errors;
      !this.$v.fechaCaducidadUsuario.required &&
        errors.push("Fecha de caducidad es requerido.");
      return errors;
    },
    cargoUsuarioError() {
      const errors = [];
      if (!this.$v.cargoUsuario.$dirty) return errors;
      !this.$v.cargoUsuario.required && errors.push("Cargo es requerido.");
      !this.$v.cargoUsuario.txtField && errors.push("No utilice números ni caracteres especiales en este campo.");
      return errors;
    },
    infoAdicionalUsuarioError() {
      const errors = [];
      if (!this.$v.infoAdicionalUsuario.$dirty) return errors;
      !this.$v.infoAdicionalUsuario.txtArea &&
        errors.push("Información adicional no es válido.");
      return errors;
    },
    divisionUsuarioError() {
      const errors = [];
      if (!this.$v.divisionUsuario.$dirty) return errors;

      if (
        !(this.verifyMoreThanOneUnidad && !this.verifyDUDA) &&
        this.divisionUsuario == null
      )
        errors.push("Unidad es requerida.");
      if (
        this.verifyMoreThanOneUnidad &&
        !this.verifyDUDA &&
        this.stckUnidades.length == 0
      )
        errors.push("Unidad es requerida.");

      return errors;
    },
  },
  methods: {
    ...mapMutations("solicitudCuenta", [
      "setFormMainCuenta",
      "cleanUnidadesUsuario",
    ]),
    ...mapActions("solicitudCuenta", ["$touchValue"]),
    // Las funciones siguientes estan encargadas de actualizar el state de la solicitud de cuenta y encargarse de validar,
    // en base a la instancia definida de vuelidate en el store,
    // sin embargo al tener los eventos del tipo input lo cual actualiza los valores y los valida de una vez,
    //  existe un problema de performace, una solucion facil pero no ideal seria valiadar con el evento blur
    updateCargoUsuario(value) {
      this.$touchValue("cargoUsuario");
      this.$store.commit("solicitudCuenta/updateCargoUsuario", value);
    },
    updateDivisionUsuario(value) {
      this.$touchValue("divisionUsuario");
      this.$store.commit("solicitudCuenta/updateDivisionUsuario", value);
    },
    updateEmailUsuario(value) {
      this.$touchValue("emailUsuario");
      this.$store.commit(
        "solicitudCuenta/updateEmailUsuario",
        value.toLowerCase()
      );
    },
    updateFechaCaducidadUsuario(value) {
      this.$touchValue("fechaCaducidadUsuario");
      this.$store.commit("solicitudCuenta/updateFechaCaducidadUsuario", value);
    },
    updateInfoAdicionalUsuario(value) {
      this.$touchValue("infoAdicionalUsuario");
      this.$store.commit(
        "solicitudCuenta/updateInfoAdicionalUsuario",
        value.trim()
      );
    },
    updateProfesionUsuario(value) {
      this.$touchValue("profesionUsuario");
      this.$store.commit("solicitudCuenta/updateProfesionUsuario", value);
    },
    updateRolPerfilUsuario(value) {
      this.$touchValue("rolPerfilUsuario");
      this.$store.commit("solicitudCuenta/updateRolPerfilUsuario", value);
    },
    updateTelefonoUsuario(value) {
      this.$touchValue("telefonoUsuario");
      this.$store.commit("solicitudCuenta/updateTelefonoUsuario", value);
    },
    updateTituloUsuario(value) {
      this.$touchValue("tituloUsuario");
      this.$store.commit("solicitudCuenta/updateTituloUsuario", value);
    },
    updateDetalleModificacionUsuario(value) {
      this.$store.commit(
        "solicitudCuenta/updateDetalleModificacionUsuario",
        value
      );
    },
    async fetchProfesiones() {
      try {
        const response = await this.services.Usuarios.getProfesiones();
        if (response) {
          this.ctlProfesiones = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRolesPerfiles() {

      if (this.counter_response > 5) {
        console.error("Las peticiones fueron mas de 5 veces");

        this.temporalAlert({
          message:
            "No se pudo obtener los roles/perfiles, intente nuevamente, si el problema persiste contacte al administrador.",
          show: true,
          type: "warning",
        });
        return;
      }
      const { data, status } =
        await this.services.Usuarios.getPerfilRolDependencia();
      if (status === 200) {
        if (data.length == 0) {
          this.counter_response++;
        }
        this.ctlRolesPerfiles = data;

        if (!isNil(this.perfilRolPreSeleccionado)) {
          const rolPreSeleccionado = data.find((rol) => rol.id === this.perfilRolPreSeleccionado);

          if (rolPreSeleccionado) {
            this.$store.commit("solicitudCuenta/updateRolPerfilUsuario", rolPreSeleccionado);
          }
        }
      }

    },
    async fetchDUDA() {
      try {
        let response = null;

        if (
          this.haveRole("ROLE_SOLICITUD_USUARIO_APROBADO_TITULAR") ||
          this.haveRole("ROLE_UACI")
        ) {
          // const id_institucion = this.selectedUnidad.instituciones.id;
          response = await this.services.Usuarios.getDUDA();
        } else {
          response = await this.services.Usuarios.getDUDAEmpleado();
        }

        if (response) {
          if (response.data.length === 1 && this.divisionUsuario == null) {
            this.verifyDUDA = true;
            this.updateDivisionUsuario(response.data[0]);
          }
          this.ctlDUDA = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async obtainLinkDocument(route) {
      try {
        const response = await this.services.Usuarios.getUrlDocumento({
          ruta: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          // Descargar el archivo
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();

          this.document = file;
        }
      } catch (error) {
        console.log(error);
      }
    },
    agregarUnidadUsuario() {
      if (this.stckUnidades?.length > 0) {
        if (
          this.stckUnidades?.find((item) => item.id === this.divisionUsuario.id)
        ) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message: "no puede agregar la misma unidad",
          });
        } else {
          this.$store.commit(
            "solicitudCuenta/addUnidadUsuario",
            this.divisionUsuario
          );
          this.$store.commit("solicitudCuenta/updateDivisionUsuario", null);
        }
      } else {
        this.$store.commit(
          "solicitudCuenta/addUnidadUsuario",
          this.divisionUsuario
        );
        this.$store.commit("solicitudCuenta/updateDivisionUsuario", null);
      }
    },
    elimminarUnidadUsuario(id) {
      this.$store.commit("solicitudCuenta/removeUnidadUsuario", id);
    },
    async verificarJefes() {
      if (
        this.divisionUsuario?.id &&
        this.rolPerfilUsuario?.id &&
        [6].includes(this.rolPerfilUsuario?.id)
      ) {
        try {
          const response = await this.services.SolicitudesUsuarios.validarJefes(
            {
              id_unidad: this.divisionUsuario?.id,
            }
          );

          if (response?.data?.id_usuario > 0) {
            this.btnSolicitar = true;
            this.temporalAlert({
              show: true,
              type: "warning",
              message: `Ya existe un jefe en la unidad: ${this.divisionUsuario?.nombre}`,
            });
          } else {
            this.btnSolicitar = false;
          }
        } catch (error) {
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "No se ha podido validar la existencia de un jefe para esta unidad",
          });
        }
      } else {
        this.btnSolicitar = false;
      }
    },
  },
  watch: {
    divisionUsuario(value) {
      if (value == null) {
        this.fetchDUDA();
      }
    },
    rolPerfilUsuario(value) {
      this.counter_response = 0;
      if (value == null) {
        this.fetchRolesPerfiles();
      } else {
        if (value.id != 7) {
          this.cleanUnidadesUsuario();
        }
      }
    },
    perfilRolPreSeleccionado: {
      handler(value) {
        if (value) {
          const rolPreSeleccionado = this.ctlRolesPerfiles.find((rol) => rol.id === value);

          if (rolPreSeleccionado) {
            this.$store.commit("solicitudCuenta/updateRolPerfilUsuario", rolPreSeleccionado);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchProfesiones();
  },
};
</script>
