<template>
    <v-row>
        <v-col cols="12" md="6">
          <div>
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Correo eletrónico
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.emailUsuario ?? '-' }}</p>
          </div>
          <div class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Teléfono
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.telefonoUsuario ?? '-' }}</p>
          </div>
          <div class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Profesión
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.profesionUsuario?.nombre ?? '-' }}</p>
          </div>
          <div class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              Rol o perfil
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.rolPerfilUsuario?.nombre ?? '-' }}</p>
          </div>
          <div v-if="formMainCuenta.urlDocumentoInstitucional" class="mt-4">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                Documento
            </h6>
            <p class="mb-0 text-body-2 font-weight-medium">
                <a
                    @click="obtainLinkDocument(formMainCuenta.urlDocumentoInstitucional)"
                    class="secondary--text text-uppercase text-caption font-weight-bold"
                    target="_blank"
                >
                    <v-icon color="secondary">mdi-download</v-icon>
                    Descargar documento
                </a>
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
            <div>
                <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                Fecha de caducidad
                </h6>
                <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.fechaCaducidadUsuario ?? '-' }}</p>
            </div>
            <div class="mt-4">
                <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                    Cargo funcional
                </h6>
                <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.cargoUsuario ?? '-' }}</p>
            </div>
            <div class="mt-4">
                <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                    División, unidad, departamento o área
                </h6>
                <p class="mb-0 text-body-2 font-weight-medium">
                    <template v-if="formMainCuenta.divisionUsuario">
                        {{ formMainCuenta.divisionUsuario.nombre }}
                        ({{ formMainCuenta.divisionUsuario.institucion }})
                    </template>
                    <template v-else>-</template>
                </p>
            </div>
            <div class="mt-4">
                <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                    Información adicional
                </h6>
                <p class="mb-0 text-body-2 font-weight-medium">{{ formMainCuenta.infoAdicionalUsuario ?? '-' }}</p>
            </div>
            <div v-if="formMainCuenta.detalleModificacionUsuario" class="mt-4 detalle-modificaciones">
                <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                    Detalle de modificaciones a realizar
                </h6>
                <div class="mb-0 text-body-2 font-weight-medium" v-html="formMainCuenta.detalleModificacionUsuario ?? '-'" />
            </div>
        </v-col>
    </v-row>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'InformacionUsuarioLectura',
    computed: {
        ...mapState("solicitudCuenta", ["formMainCuenta"]),
    },
    methods: {
        async obtainLinkDocument(route) {
            try {
                const response = await this.services.Usuarios.getUrlDocumento({ ruta: route });
                if (response) {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const file = new File([blob], "documento.pdf", { type: "application/pdf" });

                    // Descargar el archivo
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(file);
                    link.download = route.toString();
                    link.click();
                }
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>
<style scoped>
:deep(.detalle-modificaciones p) {
    margin-bottom: 0 !important;
}
</style>